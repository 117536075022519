










































































































































import Vue, { PropType } from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import {
  IndividualInterviewHistory,
  InterviewReportDetails,
  ReportDetail,
  UpdateZappyInterviewListingObjectPayload,
  RatingFeedback
} from "@/store/modules/recruiter/interfaces";
import { get_name_initials } from "@/utils/global";
import {
  get_report_icon,
  capitalize_words
} from "@/utils/principle_assessments";
import UserRating from "@/components/shared/view_profile/UserRating.vue";
import {
  GET_USER_DETAILS,
  USER_PROFILE_BY_ID,
  USER_DETAILS_BY_ID
} from "@/store/modules/auth/constants";
import {
  ZAPPYHIRE_CREATE_CANDIDATE,
  ZAPPYHIRE_SSO,
  ZAPPYHIRE_JOB_POST,
  UPDARTE_INTERVIEW_LISTING_OBJECT,
  GET_INTERVIEW_LISTING_OBJECT
} from "@/store/modules/recruiter/constants";
import { ZappyhireCandidate } from "@/interfaces/data_objects/user";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { User } from "@/interfaces/data_objects/user";
import { GET_JOB_BY_ID } from "@/store/modules/common/constants";
import { ZappyhirePostjobPayload } from "@/interfaces/recruiter/jobs/post_job/zappyhire_post_job";
import { SkillList } from "@/store/modules/common/interfaces";

import moment from "moment";
export default Vue.extend({
  name: "ViewCandidateInterviewHistory",
  components: { UserRating },
  data() {
    return {
      report: [] as InterviewReportDetails[],
      export_logo: require("@/assets/export.svg"),
      ats_loading: false
    };
  },
  props: {
    details: {
      type: Object as PropType<IndividualInterviewHistory>,
      required: true
    }
  },
  computed: {
    ...mapGetters("auth", {
      user_details: GET_USER_DETAILS
    }),
    ...mapGetters("recruiter", {
      get_interview_listing_obj: GET_INTERVIEW_LISTING_OBJECT
    })
  },
  mounted() {
    const interview_obj = this.get_interview_listing_obj(this.details.job_id);
    if (interview_obj) this.details.zappy_job_id = interview_obj.zappy_job_id;
    const history = this.details.candidate_interview_history;
    if (history) {
      let report = JSON.parse(history).report;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let final_report: { [key: string]: any } = {};
      let tech_summary = "";
      let hr_summary = "";

      if (report) {
        if (report.techReport) {
          tech_summary = JSON.parse(report.techReport)["interview summary"];
          final_report["Technical Report"] = JSON.parse(report.techReport)[
            "performance metric"
          ];
        }
        if (report.hrReport) {
          hr_summary = JSON.parse(report.hrReport)["interview summary"];
          final_report["Non-Technical Report"] = JSON.parse(report.hrReport)[
            "performance metric"
          ];
        }

        let obj = {} as ReportDetail;
        let obj2 = [] as ReportDetail[];

        for (let key1 in final_report) {
          for (const [key, val] of Object.entries(final_report[key1])) {
            const icon_details = get_report_icon(key);
            obj = {
              bg_color: icon_details.bg_color,
              color: icon_details.color,
              icon: icon_details.icon,
              key: capitalize_words(key),
              rating:
                (val as RatingFeedback)?.ranking ??
                (val as RatingFeedback)?.Ranking ??
                0,
              reason:
                (val as RatingFeedback)?.Reason ??
                (val as RatingFeedback)?.reason ??
                ""
            };
            obj2.push(obj);
          }
          this.report.push({ type: key1, value: obj2 });
          obj2 = [];
        }
      }

      this.report.push({
        type: "Summary",
        value: tech_summary + " " + hr_summary
      });
    }
  },
  methods: {
    get_name_initials,
    ...mapActions("recruiter", {
      create_zappyhire_candidate: ZAPPYHIRE_CREATE_CANDIDATE,
      generate_zappyhire_sso_token: ZAPPYHIRE_SSO,
      zappyhire_post_job: ZAPPYHIRE_JOB_POST
    }),
    ...mapMutations("recruiter", {
      update_interview_listing_object: UPDARTE_INTERVIEW_LISTING_OBJECT
    }),
    ...mapActions("auth", {
      get_user: USER_PROFILE_BY_ID,
      get_user_detail: USER_DETAILS_BY_ID
    }),
    ...mapMutations({
      root_notification: ROOT_NOTIFICATION,
      root_error: ROOT_ERROR
    }),
    ...mapActions("common", {
      fetch_job_by_id: GET_JOB_BY_ID
    }),
    async export_to_ats() {
      this.ats_loading = true;
      if (this.user_details?.zappyhire_configuration?.user_id) {
        // Create Job If Zappy Id not Exist
        if (!this.details?.zappy_job_id) await this.create_zapphire_job();

        if (!this.details.zappy_job_id) {
          this.root_error(this.$t("shared.zappyhire-job-error"));
          this.ats_loading = false;
          return;
        }
        // Fetching Candidate Profile By Selected Candidate Id
        const candidate_profile = await this.get_user(
          this.details?.candidate_id
        );
        // Fetching Candidate Detail By Selected Candidate Id
        const candidate_detail = await this.get_user_detail(
          this.details?.candidate_id
        );
        // If Candidate Profile And Detail Exist
        if (candidate_profile && candidate_detail) {
          // Create ATS Payload
          const payload = this.create_ats_payload(
            candidate_profile,
            candidate_detail
          );
          const response = await this.create_zappyhire_candidate({
            job_id: this.details.zappy_job_id,
            candidate_id: this.details.candidate_id,
            payload
          });
          // If candidate synced perfectely
          if (response) {
            // Generating SSO token
            const token = await this.generate_zappyhire_sso_token();
            if (token) {
              const a = document.createElement("a");
              a.href = `${process.env.VUE_APP_ATS_URL}?token=${token}`;
              a.target = "_blank";
              a.classList.add("d-none");
              const body = document.getElementsByTagName("body");
              body[0].appendChild(a);
              a.click();
              body[0].removeChild(a);
            } else {
              this.root_error(this.$t("shared.failed-sso"));
            }
          } else {
            this.root_error(this.$t("shared.failed-sso"));
          }
        } else {
          this.root_error(this.$t("shared.no-candidate"));
        }
      } else {
        this.root_notification(this.$t("shared.ats-persmission"));
      }
      this.ats_loading = false;
    },
    create_ats_payload(
      candidate_profile: Candidates.CandidateDetails,
      candidate_detail: User
    ): ZappyhireCandidate {
      const payload = {
        first_name: candidate_detail.first_name,
        last_name: candidate_detail.last_name,
        email: candidate_detail.email,
        country_code: "+97",
        phone_number: "2362623231",
        gender: "M",
        total_experience_years:
          candidate_profile?.profile?.total_experience.trim()[0] || "1",
        highest_academic_eligibility: "GA",
        expected_salary: 10,
        current_salary: 5,
        notice_period: 30,
        academics: [],
        work_experience: [],
        skills: ""
      } as ZappyhireCandidate;
      payload.work_experience = Object.keys(
        candidate_profile?.profile?.work_history_detailed || {}
      ).map((key) => {
        const job = candidate_profile?.profile?.work_history_detailed[key];
        let start_date = moment().format("YYYY-MM-DD");
        let end_date = moment().format("YYYY-MM-DD");
        return {
          employer: job?.organization,
          designation: job?.job_title,
          start_date: start_date,
          end_date: end_date,
          duration: 1
        };
      });
      payload.academics = Object.keys(
        candidate_profile?.profile?.education_detailed || {}
      ).map((key) => {
        const work = candidate_profile?.profile?.education_detailed[key];
        let joining_year = new Date().getFullYear();
        let passout_year = new Date().getFullYear();
        return {
          college: work?.organization_name,
          course: work?.degree_title,
          course_level: "GA",
          score: 50,
          joining_year: joining_year,
          passout_year: passout_year
        };
      });
      const skills: SkillList[] = (candidate_profile.skills || []).map(
        (skill) => ({
          group: skill.group,
          ref_skill: skill.ref_skill,
          skill: skill.skill || "" // the case where skill is undefined
        })
      );
      let final_skills = "";
      for (let skill of skills) {
        final_skills += skill.ref_skill + ", ";
      }
      if (final_skills.endsWith(", "))
        final_skills = final_skills.slice(0, final_skills.length - 2);
      payload.skills = final_skills;
      return payload;
    },
    async create_zapphire_job() {
      // Fetch Job
      const job = await this.fetch_job_by_id(this.details.job_id);
      const skills: string[] = JSON.parse(job?.skills_list);
      let final_skills = "";
      final_skills = skills.join(", ");
      let experience = 1;
      if (job?.experience) {
        experience = parseInt(job.experience.split("")[0]);
      }
      // Create Zappyhire Job payload
      const zappyhire_job = {
        job_id: job.jid,
        job_description: job.job_description,
        title: job.job_title,
        location_split: {
          kochi: 1,
          tvm: 1
        },
        skills: final_skills,
        completion_date: moment().format("YYYY-MM-DD"),
        academic_eligibility: "G",
        min_experience: experience || 1,
        max_experience: experience || 1,
        preferred_current_salary: "5-10"
      } as ZappyhirePostjobPayload;
      // Post Zappyhire job
      const response = await this.zappyhire_post_job(zappyhire_job);
      if (response) {
        const z_jid = response.results.job_id;
        this.details.zappy_job_id = z_jid;
        const payload: UpdateZappyInterviewListingObjectPayload = {
          job_id: this.details.job_id,
          zappy_job_id: z_jid
        };
        this.update_interview_listing_object(payload);
      } else {
        this.root_error(this.$t("shared.zappyhire-job-error"));
      }
      return;
    }
  }
});
